exports.components = {
  "component---src-templates-announcement-js": () => import("./../../../src/templates/announcement.js" /* webpackChunkName: "component---src-templates-announcement-js" */),
  "component---src-templates-center-js": () => import("./../../../src/templates/center.js" /* webpackChunkName: "component---src-templates-center-js" */),
  "component---src-templates-method-js": () => import("./../../../src/templates/method.js" /* webpackChunkName: "component---src-templates-method-js" */),
  "component---src-templates-pages-announcements-js": () => import("./../../../src/templates/pages/announcements.js" /* webpackChunkName: "component---src-templates-pages-announcements-js" */),
  "component---src-templates-pages-centers-js": () => import("./../../../src/templates/pages/centers.js" /* webpackChunkName: "component---src-templates-pages-centers-js" */),
  "component---src-templates-pages-contact-js": () => import("./../../../src/templates/pages/contact.js" /* webpackChunkName: "component---src-templates-pages-contact-js" */),
  "component---src-templates-pages-context-js": () => import("./../../../src/templates/pages/context.js" /* webpackChunkName: "component---src-templates-pages-context-js" */),
  "component---src-templates-pages-imprint-js": () => import("./../../../src/templates/pages/imprint.js" /* webpackChunkName: "component---src-templates-pages-imprint-js" */),
  "component---src-templates-pages-index-js": () => import("./../../../src/templates/pages/index.js" /* webpackChunkName: "component---src-templates-pages-index-js" */),
  "component---src-templates-pages-methods-js": () => import("./../../../src/templates/pages/methods.js" /* webpackChunkName: "component---src-templates-pages-methods-js" */),
  "component---src-templates-pages-privacy-js": () => import("./../../../src/templates/pages/privacy.js" /* webpackChunkName: "component---src-templates-pages-privacy-js" */),
  "component---src-templates-pages-the-project-js": () => import("./../../../src/templates/pages/the_project.js" /* webpackChunkName: "component---src-templates-pages-the-project-js" */)
}

